import { ApolloConsumer } from "@apollo/client";
import React from "react";
import BettingForm from "./BettingForm";
import "./bet-form.css";
import { Button } from "@aws-amplify/ui-react";
import { capitalizeFirstLetter } from "../../utils";

const BettingPage = ({ signOut, user }) => {
  return (
    <ApolloConsumer>
      {(client) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <h2>Welcome {capitalizeFirstLetter(user.username)}!</h2>
            <Button variant="text" onClick={signOut}>
              Sign out
            </Button>
          </div>
          <BettingForm client={client} />
        </>
      )}
    </ApolloConsumer>
  );
};

export default BettingPage;
