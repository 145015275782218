import React from "react";
import Card from "../../Components/Card";
import { capitalizeFirstLetter } from "../../utils";

function Game({ game }) {
  const bets = [...game.bets].sort((a, b) => {
    if (a.player < b.player) {
      return -1;
    }
    if (a.player > b.player) {
      return 1;
    }
    return 0;
  });

  const redzone =
    game.teamInPosession &&
    game.teamInPosession !== game.ballOnSide &&
    game.ballOnYardLine <= 20;

  return (
    <Card
      style={{
        maxWidth: "500px",
        margin: "10px",
        border: `${redzone ? "2px solid red" : "none"}`,
      }}
    >
      <style>{`#game-header-background-${game.awayTeam.nickname}::before { background-image: url(${game.awayTeam.logo}), url(${game.homeTeam.logo}) }`}</style>
      <div
        className="game-header-background"
        id={`game-header-background-${game.awayTeam.nickname}`}
      >
        <div className="game-header-container">
          <header className="game-header">
            {game.gameState !== "UPCOMING" ? (
              <div className="game-team-score ">{game.awayScore || 0}</div>
            ) : null}
            <div className="game-header-teams">{`${game.awayTeam.nickname} @ ${game.homeTeam.nickname}`}</div>
            {game.gameState !== "UPCOMING" ? (
              <div className="game-team-score ">{game.homeScore || 0}</div>
            ) : null}
            <GameInfo game={game} />
          </header>

          <div className="bets-container">
            {bets.map((bet) => (
              <div
                key={bet.id}
                className={`bet-container ${
                  game.gameState !== "UPCOMING" && game.winner !== bet.pick
                    ? "wrong"
                    : ""
                }`}
              >
                <div>{capitalizeFirstLetter(bet.player)}</div>
                <div className="bet-pick">
                  {bet.pick === "TIE" ? (
                    <div>X</div>
                  ) : bet.pick === "HOME" || bet.pick === "AWAY" ? (
                    <img
                      className="pres-bet-logo"
                      alt="team logo"
                      src={
                        bet.pick === "HOME"
                          ? game.homeTeam.logo
                          : game.awayTeam.logo
                      }
                    />
                  ) : (
                    <div>–</div>
                  )}
                </div>
                <div style={{ textAlign: "center" }}>{bet.score}</div>
                {bet.potentialScore ? (
                  <div
                    className="pres-potential"
                    style={{ textAlign: "center" }}
                  >{`(${bet.potentialScore})`}</div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}

const GameInfo = ({ game }) => (
  <>
    <p className="game-header-state">
      <span className="game-header-quarter">
        {game.gameState === "UPCOMING"
          ? new Date(game.isoKickoff * 1000).toLocaleDateString("en-US", {
              timeZone: "Europe/Stockholm",
              weekday: "short",
              month: "short",
              day: "numeric",
            }) +
            " " +
            new Date(game.isoKickoff * 1000).toLocaleTimeString("sv-SE", {
              timeZone: "Europe/Stockholm",
              hour: "2-digit",
              minute: "2-digit",
            })
          : game.quarter}
      </span>
    </p>
    {game.teamInPossession ? (
      <p className="game-header-play">
        <span>
          {` ${game.teamInPossession}: ${game.down} and ${game.yardsToGo} @ ${game.ballOnSide} ${game.ballOnYardLine}`}
        </span>
      </p>
    ) : null}
  </>
);

export default Game;
