import React from "react";
import { Link } from "react-router-dom";

const PageHeader = ({ user }) => {
  return (
    <header
      style={{
        backgroundColor: "var(--main-header-color)",
        color: "white",
        padding: "10px 20px",
        position: "sticky",
        top: 0,
        boxShadow: "0 2px 2px 0 rgba(0,0,0,.16)",
        zIndex: 10,
      }}
    >
      <nav
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          style={{ color: "white", margin: "3px", textDecoration: "none" }}
        >
          <h1 style={{ fontSize: "1.3em" }}>NFL SBL 2024</h1>
        </Link>

        <Link
          to="/bet"
          style={{
            margin: "3px",
            textDecoration: "none",
          }}
        >
          <div
            style={{
              color: "white",
              backgroundColor: "var(--primary-color-light)",
              fontWeight: "bold",
              fontSize: "1.3em",
              border: "2px solid White",
              height: "40px",
              width: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
          >
            {user.username.charAt(0).toUpperCase()}
          </div>
        </Link>
      </nav>
    </header>
  );
};

export default PageHeader;
