/* eslint-disable import/no-unresolved */
import { render } from "react-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";

import { Amplify } from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import { Authenticator } from "@aws-amplify/ui-react";

import { createAuthLink } from "aws-appsync-auth-link";

import { createHttpLink } from "apollo-link-http";

const config = Amplify.configure({
   Auth: { 
       region: "eu-north-1",
       userPoolId: "eu-north-1_QfpsaHWqj",
       userPoolWebClientId: "5qacns0970e350asqmrs16gjue", 
   }});

import HomePage from "./pages/Home/Home";
import BettingPage from "./pages/UserPage/BettingPage";
import PageHeader from "./PageHeader";

const url = "https://afp6w6tfjzevtcyd52fanbo4d4.appsync-api.eu-north-1.amazonaws.com/graphql";
const region = config.region;
const auth = {
  type: "AMAZON_COGNITO_USER_POOLS",
  jwtToken: async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken(),
};

// eslint-disable-next-line no-unused-vars
const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createHttpLink({ uri: url }),
]);

const client = new ApolloClient({
  link,
  // uri: "http://localhost:4000",
  cache: new InMemoryCache(),
});

const App = () => (
  <ApolloProvider client={client}>
    <Authenticator>
      {({ signOut, user }) => (
        <BrowserRouter>
          <PageHeader signOut={signOut} user={user} />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="bet"
              element={<BettingPage signOut={signOut} user={user} />}
            />
          </Routes>
        </BrowserRouter>
      )}
    </Authenticator>
  </ApolloProvider>
);

render(<App />, document.getElementById("root"));
