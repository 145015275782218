import React from "react";
// import Leaderboard from "./Leaderboard";
import Week from "./Week";

const HomePage = () => {
  return (
    <>
      {/* <Leaderboard /> */}
      <Week />
    </>
  );
};

export default HomePage;
