import { gql, useQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import BetGame from "./BetGame";
import Button from "@mui/material/Button";

import Card from "../../Components/Card";
import { GET_GAMES_TO_BET } from "../../queries";
import { HiLockClosed } from "react-icons/hi";
import { SAVE_BETS, LOCK_BETS } from "../../mutations";
import Spinner from "../../Components/Spinner";

const BettingForm = ({ client }) => {
  const [bettingSum, setBettingSum] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const { loading, error, data } = useQuery(GET_GAMES_TO_BET);
  const [saveBets, { loading: saving, error: saveError }] =
    useMutation(SAVE_BETS);
  const [lockBets, { loading: locking, error: lockError }] =
    useMutation(LOCK_BETS);

  const [potentialWins, setPotentialWins] = useState({});

  function validate() {
    let hasInvalidFields = false;
    const hasUpcomingGames =
      data?.getRegularSeasonWeek?.games?.filter(
        (game) => game.bets[0].bettingState === "NOT_OPENED"
      ).length > 0;

    data?.getRegularSeasonWeek?.games
      ?.filter((game) => game.bets[0].bettingState === "OPEN")
      .forEach((game) => {
        if (game.bets[0].pick !== "HOME" && game.bets[0].pick !== "AWAY") {
          hasInvalidFields = true;
        }
      });

    const correctBettingSum =
      (!hasUpcomingGames && bettingSum === 0) ||
      (hasUpcomingGames && bettingSum >= 0)
        ? true
        : false;

    if (!hasInvalidFields && correctBettingSum) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (data?.getRegularSeasonWeek?.games.length) {
      let totalPlacedBets = 0;
      for (let i = 0; i < games.length; i++) {
        const game = games[i];
        totalPlacedBets += game.bets[0].stake || 0;
      }
      setBettingSum(data.getRegularSeasonWeek.stake - totalPlacedBets);
    }
  }, [data]);

  useEffect(() => {
    calcPotentialWins(
      data?.getRegularSeasonWeek?.games?.filter(
        (game) => game.bets[0].bettingState === "OPEN"
      )
    );
  }, [data]);

  useEffect(() => {
    setIsValid(validate());
  }, [data, bettingSum]);

  const calcPotentialWins = (games) => {
    const potentialWins = {};
    if (games?.length) {
      games.forEach((game) => {
        const bet = game.bets[0];

        const win =
          bet.pick === "HOME"
            ? bet.homeOdds * bet.stake
            : bet.pick === "AWAY"
            ? bet.awayOdds * bet.stake
            : 0;
        potentialWins[game.id] = win;
      });
      setPotentialWins(potentialWins);
    }
  };

  const saveForm = () => {
    if (!saving && data) {
      const toSave = data.getRegularSeasonWeek.games.filter(
        (game) => game.bets[0].bettingState === "OPEN"
      );
      const gameBets = toSave.map((game) => {
        return {
          id: game.bets[0].id,
          pick: game.bets[0].pick,
          stake: game.bets[0].stake,
        };
      });

      saveBets({ variables: { gameBets } });
    }
  };

  const lockInBets = () => {
    if (confirm("Are you sure you want to lock in those bets?")) {
      if (!saving && !locking && data) {
        const toSave = data.getRegularSeasonWeek.games.filter(
          (game) => game.bets[0].bettingState === "OPEN"
        );
        const gameBets = toSave.map((game) => {
          return {
            id: game.bets[0].id,
            pick: game.bets[0].pick,
            stake: game.bets[0].stake,
          };
        });

        lockBets({ variables: { gameBets } });
      }
    }
  };

  const handleBetChange = (e) => {
    const { id, type } = e.target.dataset;
    let value = type === "stake" ? Number(e.target.value) : e.target.value;
    const betId = `GameBet:${id}`;

    if (type === "pick") {
      const bet = client.readFragment({
        id: `${betId}`,
        fragment: gql`
          fragment MyBet on GameBet {
            pick
          }
        `,
      });
      if (value === bet.pick) {
        value = "NONE";
      }
    }

    client.writeFragment({
      id: betId,
      fragment: gql`
          fragment New${type} on GameBet {${type}}
        `,
      data: {
        [type]: value,
      },
    });
  };

  if (loading || saving || locking) {
    return <Spinner />;
  }

  if (error || saveError || lockError) {
    return <p>Hm...</p>;
  }

  const { games } = data.getRegularSeasonWeek;
  const openForBets = games?.filter(
    (game) => game.bets[0].bettingState === "OPEN"
  );
  const upcomingGames = games?.filter(
    (game) => game.bets[0].bettingState === "NOT_OPENED"
  );

  openForBets?.forEach((game) => {
    game.bets?.forEach((bet) => {
      const setStake = client.readFragment({
        id: `GameBet:${bet.id}`,
        fragment: gql`
          fragment Stake on GameBet {
            stake
          }
        `,
      });

      if (setStake.stake === null) {
        client.writeFragment({
          id: `GameBet:${bet.id}`,
          fragment: gql`
            fragment NewStake on GameBet {
              stake
            }
          `,
          data: {
            stake:
              data.getRegularSeasonWeek.stake /
              data.getRegularSeasonWeek.games.length,
          },
        });
      }
    });
  });

  const unlockedBets = openForBets.filter((game) => {
    return game.bets[0].locked === false;
  });

  return (
    <Card>
      <section className="bet-form-container">
        <div>
          <header className="bet-form-header">
            <div
              className={`amount ${bettingSum >= 0 ? "" : "minus"}`}
            >{`$ ${bettingSum}`}</div>
          </header>

          {openForBets.length > 0 ? (
            <form
              className="bet-form"
              onSubmit={(e) => {
                e.preventDefault();
                saveForm(e);
              }}
            >
              {openForBets.map((game) => (
                <BetGame
                  game={game}
                  key={game.id}
                  handleBetChange={handleBetChange}
                  potentialWin={potentialWins[game.id]}
                />
              ))}
              {unlockedBets.length > 0 ? (
                <div className="form-button-container">
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      borderColor: "var(--main-header-color)",
                      color: "var(--main-header-color)",
                      marginRight: "10px",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    disabled={!isValid}
                    onClick={lockInBets}
                    type="button"
                    variant="contained"
                    sx={{
                      backgroundColor: "var(--main-header-color)",
                    }}
                  >
                    <HiLockClosed
                      style={{
                        position: "relative",
                        top: "-1px",
                        marginRight: "5px",
                      }}
                    />
                    Lock in bets
                  </Button>
                </div>
              ) : null}
            </form>
          ) : (
            <p>Nothing to bet on right now</p>
          )}
        </div>
        <div>
          {upcomingGames
            .sort((a, b) => {
              if (a.isoKickoff < b.isoKickoff) {
                return -1;
              }
              if (a.isoKickoff > b.isoKickoff) {
                return 1;
              }
              return 0;
            })
            .map((game) => (
              <BetGame game={game} key={game.id} disabled={true} />
            ))}
        </div>
      </section>
    </Card>
  );
};

export default BettingForm;
