import React from "react";

const Card = ({ children, style }) => (
  <div
    style={{
      backgroundColor: "#fff",
      padding: "10px 0 10px 0",
      borderRadius: "5px",
      marginBottom: "10px",
      boxShadow: "0 2px 2px 0 rgba(0,0,0,.16)",
      width: "100%",
      ...style,
    }}
  >
    {children}
  </div>
);

export default Card;
