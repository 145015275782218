import griffin from "../resources/peter.webp";

const Spinner = () => (
  <img
    style={{
      animation: `spin 3s linear infinite`,
      height: "200px",
      display: "block",
      margin: "0 auto",
    }}
    src={griffin}
    alt="img"
  />
);

export default Spinner;
