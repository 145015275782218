import { gql } from "@apollo/client";

export const SAVE_BETS = gql`
  mutation saveBets($gameBets: [GameBetInput!]!) {
    saveGameBets(gameBets: $gameBets)
  }
`;

export const LOCK_BETS = gql`
  mutation saveBets($gameBets: [GameBetInput!]!) {
    lockGameBets(gameBets: $gameBets)
  }
`;

// export const LOCK_IN_BETS = gql``;
