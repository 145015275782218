import { useState } from "react";
import { HiChevronDown, HiChevronRight } from "react-icons/hi";

const FoldableContainer = ({ children, label, LSKey }) => {
  const [expanded, setExpanded] = useState(() => {
    return JSON.parse(localStorage.getItem(LSKey));
  });

  function setExpState(state) {
    localStorage.setItem(LSKey, state);
    setExpanded(state);
  }

  return (
    <div className="expandable-container">
      <div
        onClick={() => setExpState(!expanded)}
        onKeyDown={(e) => (e.code === "Enter" ? setExpState(!expanded) : null)}
        role="button"
        tabIndex={0}
        style={{
          padding: "10px 3px 10px 3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ marginRight: "8px" }}>{label}</span>{" "}
        {expanded ? <HiChevronDown /> : <HiChevronRight />}
      </div>
      {expanded ? <div>{children}</div> : null}
    </div>
  );
};

export default FoldableContainer;
