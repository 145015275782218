import React from "react";

const BetGame = ({ game, handleBetChange, disabled, potentialWin }) => {
  return (
    <>
      {!disabled ? (
        <div
          style={{
            width: "234px",
            textAlign: "right",
            position: "relative",
            top: "14px",
            color: "var(--main-header-color)",
            fontSize: "0.9em",
          }}
        >
          {new Date(game.isoKickoff * 1000).toLocaleDateString("en-US", {
            timeZone: "Europe/Stockholm",
            weekday: "short",
            month: "short",
            day: "numeric",
          }) +
            " " +
            new Date(game.isoKickoff * 1000).toLocaleTimeString("sv-SE", {
              timeZone: "Europe/Stockholm",
              hour: "2-digit",
              minute: "2-digit",
            })}
        </div>
      ) : null}
      <fieldset className={`bet-game ${disabled ? "disabled" : ""}`}>
        <label className="form-control">
          <input
            type="radio"
            name={game.id}
            value="AWAY"
            checked={game.bets?.length && game.bets[0].pick === "AWAY"}
            onClick={handleBetChange}
            data-id={game.bets[0].id}
            data-type="pick"
            required
            disabled={
              !(game.bets[0].bettingState === "OPEN") || game.bets[0].locked
            }
            readOnly
          />
          <div>
            <img
              className="bet-logo"
              style={{ marginRight: "5px" }}
              alt="team logo"
              src={game.awayTeam.logo}
            />
            <div>
              <p>{game.awayTeam.shortName}</p>
              {disabled ? null : (
                <p className="bet-odds">{game.bets[0].awayOdds}</p>
              )}
            </div>
          </div>
        </label>
        <div className="bet-at">@</div>
        <label className="form-control" style={{ position: "relative" }}>
          <span className="form-dollar-span">$</span>
          <input
            type="radio"
            name={game.id}
            value="HOME"
            checked={game.bets[0].pick === "HOME"}
            onClick={handleBetChange}
            data-id={game.bets[0].id}
            data-type="pick"
            required
            disabled={
              !(game.bets[0].bettingState === "OPEN") || game.bets[0].locked
            }
            readOnly
          />
          <div>
            <div>
              <p>{game.homeTeam.shortName}</p>
              {disabled ? null : (
                <p className="bet-odds">{game.bets[0].homeOdds}</p>
              )}
            </div>
            <img
              className="bet-logo"
              style={{ marginLeft: "5px" }}
              alt="team logo"
              src={game.homeTeam.logo}
            />
          </div>
          {!disabled && (
            <span className="bet-potential-win">
              {potentialWin && Math.round(potentialWin)}
            </span>
          )}
        </label>

        <input
          data-id={game.bets[0].id}
          data-type="stake"
          type="number"
          min="0"
          max="9999"
          value={game.bets[0]?.stake === null ? "" : game.bets[0]?.stake}
          onChange={handleBetChange}
          name="amount"
          disabled={
            !(game.bets[0].bettingState === "OPEN") || game.bets[0].locked
          }
          className="bet-amount-input"
        />
      </fieldset>
      {disabled ? (
        <div
          style={{
            width: "234px",
            textAlign: "center",
            position: "relative",
            top: "-30px",
            color: "grey",
            fontSize: "0.9em",
          }}
        >
          {new Date(game.isoKickoff * 1000).toLocaleDateString("en-US", {
            timeZone: "Europe/Stockholm",
            weekday: "short",
            month: "short",
            day: "numeric",
          }) +
            " " +
            new Date(game.isoKickoff * 1000).toLocaleTimeString("sv-SE", {
              timeZone: "Europe/Stockholm",
              hour: "2-digit",
              minute: "2-digit",
            })}
        </div>
      ) : null}
    </>
  );
};

export default BetGame;
