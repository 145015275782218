import React from "react";
import { useQuery } from "@apollo/client";
import Game from "./Game";
import FoldableContainer from "../../Components/FoldableContainer";
import { GET_CURRENT_WEEK } from "../../queries";
import "./week-pres.css";
import Spinner from "../../Components/Spinner";

function sortByKickoffTime(a, b) {
  if (a.isoKickoff < b.isoKickoff) {
    return -1;
  }
  if (a.isoKickoff > b.isoKickoff) {
    return 1;
  }
  return 0;
}

function Week() {
  const { loading, error, data } = useQuery(GET_CURRENT_WEEK);
  if (loading) return <Spinner />;
  if (error) return `Error: ${error.message}`;

  const ongoingGames = data.getRegularSeasonWeek.games
    .filter((game) => game.gameState === "ONGOING")
    .sort(sortByKickoffTime);
  const upcomingGames = data.getRegularSeasonWeek.games
    .filter((game) => game.gameState === "UPCOMING")
    .sort(sortByKickoffTime);
  const finishedGames = data.getRegularSeasonWeek.games
    .filter((game) => game.gameState === "FINISHED")
    .sort(sortByKickoffTime);

  return (
    <div className="week-container">
      <FoldableContainer
        LSKey="current"
        label={`Currently playing (${ongoingGames.length})`}
      >
        <div className="pres-game-container">
          {ongoingGames.map((game) => {
            return <Game game={game} key={game.id} />;
          })}
        </div>
      </FoldableContainer>
      <FoldableContainer
        LSKey="upcoming"
        label={`Upcoming games (${upcomingGames.length})`}
      >
        <div className="pres-game-container">
          {upcomingGames.map((game) => {
            return <Game game={game} key={game.id} />;
          })}
        </div>
      </FoldableContainer>
      <FoldableContainer
        LSKey="finished"
        label={`Finished games (${finishedGames.length})`}
      >
        <div className="pres-game-container">
          {finishedGames.map((game) => {
            return <Game game={game} key={game.id} />;
          })}
        </div>
      </FoldableContainer>
    </div>
  );
}

export default Week;
