import { gql } from "@apollo/client";

export const GET_LEADERBOARD = gql`
  query getLeaderboard {
    getRegularSeasonLeaderboard {
      id
      weekScores {
        id
        name
        score
      }
      weekScoresLive {
        id
        name
        score
      }
      weekScoresPossible {
        id
        name
        score
      }
      weekScoresIdeal {
        id
        name
        score
      }
      totalScores {
        id
        name
        score
      }
      totalScoresLive {
        id
        name
        score
      }
      totalScoresPossible {
        id
        name
        score
      }
      totalScoresIdeal {
        id
        name
        score
      }
    }
  }
`;

export const GET_GAMES_TO_BET = gql`
  query getGamesToBet {
    getRegularSeasonWeek(onlyPlayerBets: true) {
      id
      stake
      games {
        id
        isoKickoff
        homeTeam {
          nickname
          logo
          shortName
        }
        awayTeam {
          nickname
          logo
          shortName
        }
        bets {
          locked
          bettingState
          homeOdds
          awayOdds
          id
          stake
          pick
        }
      }
    }
  }
`;

export const GET_CURRENT_WEEK = gql`
  query currentWeek {
    getRegularSeasonWeek {
      id
      games {
        id
        isoKickoff
        quarter
        teamInPossession
        down
        yardsToGo
        ballOnSide
        ballOnYardLine
        gameState
        winner
        awayScore
        homeScore
        homeTeam {
          id
          nickname
          city
          conference
          fullName
          shortName
          logo
        }
        awayTeam {
          id
          shortName
          fullName
          city
          nickname
          logo
        }
        bets {
          id
          pick
          score
          player
          stake
          potentialScore
        }
      }
    }
  }
`;
